<template>
  <app-module-view>
    <template slot="body">
      <app-subscriber-filter></app-subscriber-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="subscribers"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import config from '../../config'
import Preloader from '../preloader/Preloader'
import SubscriberFilter from './SubscriberFilter'
import DataTable from '../shared/DataTable'
import StoreService from '../../services/eshop/StoreService'

export default {
  name: 'SubscriberList',
  data () {
    return {
      page: 1,
      dataTableConfig: {
        fields: {
          id: this.$t('eshopSubscriber.id'),
          createdAt: this.$t('eshopSubscriber.activated_at'),
          subscription: this.$t('eshopSubscriber.code'),
          activeSince: this.$t('eshopSubscriber.activeSince'),
          activeUntil: this.$t('eshopSubscriber.activeUntil'),
          cancelledAt: this.$t('eshopSubscriber.cancelled_at'),
          userEmail: this.$t('eshopSubscriber.email'),
          productName: this.$t('eshopSubscriber.product'),
          firstOrder: this.$t('eshopSubscriber.firstOrder')
        },
        render: {
          firstOrder: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          },
          userEmail: (data) => {
            if (data) {
              return '<a href="/feUser/' + this.getUserIdByEmail(data) + '/edit">' + data + '</a>'
            }
            return data
          },
          subscription: (data) => {
            if (data) {
              return '<a href="/shop/subscription/subscription/' + data + '/edit">' + this.getSubscriptionCodeById(data) + '</a>'
            }
            return data
          }
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    subscribers () {
      return this.$store.getters['eshopSubscriber/list']
    },
    totalCount () {
      return this.$store.getters['eshopSubscriber/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['eshopSubscriber/totalCount'] / config.defaults.list.limit)
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appSubscriberFilter: SubscriberFilter,
    appDataTable: DataTable
  },
  methods: {
    getSubscribers () {
      this.$store.dispatch('eshopSubscriber/fetch')
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('eshopSubscriber/setPage', page)
      this.getSubscribers()
    },
    getUserIdByEmail (email) {
      return this.subscribers.find((subscriber) => email === subscriber.userEmail).feUser
    },
    getSubscriptionCodeById (subscriptionId) {
      return this.subscribers.find((subscriber) => subscriptionId === subscriber.subscription).subscriptionCode
    }
  },
  mounted () {
    this.page = this.$store.getters['eshopSubscriber/page']
    this.getSubscribers()
    StoreService.fetchAll()
  }
}
</script>

<style lang="scss">

</style>
